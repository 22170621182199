require('./bootstrap');


//Preloader Gif
const loader = document.getElementById("preloader");
window.addEventListener("load", function(){
    loader.style.display = "none";
})
//Check when html 5 video is finished
// var video = document.getElementsByTagName('video')[0];
// video.ontimeupdate = function() {getPercentage()};

// console.log(current_video_time);
// video.duration = function (e){
//     alert('Movie has finished');
// }
//
// var myPlayer = document.querySelector('video');
// var percentageCompleted = 0;
// var totalLength;
// var videoStarted, videoTwentyFive, videoFifty, videoSeventyFive, videoComplete = false;
// console.log(myPlayer.duration);
// console.log(totalLength);
// myPlayer.ontimeupdate = function() {
//     getPercentage()
// };
//
// function getPercentage() {
//     totalLength = myPlayer.duration % 60;
//     percentageCompleted = (myPlayer.currentTime / totalLength) * 100;
//     console.log(totalLength, "Movie length");
//     console.log('percentage', (percentageCompleted+'%'));
//
//     // is 0
//     if ((!videoStarted) && (percentageCompleted > 0)) {
//         console.log('VIDEO_STARTED');
//         videoStarted = true;
//
//         window.dataLayer = window.dataLayer || [];
//         window.dataLayer.push({
//             'event': 'playStart'
//         });
//     }
//     // is 25
//     if ((!videoTwentyFive) && (percentageCompleted > 25)) {
//         console.log('VIDEO_25');
//         videoTwentyFive = true;
//
//         window.dataLayer = window.dataLayer || [];
//         window.dataLayer.push({
//             'event': 'playTwentyFive'
//         });
//     }
//     // is 50
//     if ((!videoFifty) && (percentageCompleted > 50)) {
//         console.log('VIDEO_50');
//         videoFifty = true;
//
//         window.dataLayer = window.dataLayer || [];
//         window.dataLayer.push({
//             'event': 'playFifty'
//         });
//     }
//     // is 75
//     if ((!videoSeventyFive) && (percentageCompleted > 75)) {
//         console.log('VIDEO_75');
//         videoSeventyFive = true;
//
//         window.dataLayer = window.dataLayer || [];
//         window.dataLayer.push({
//             'event': 'playSeventyFive'
//         });
//     }
//     // is 100
//     if ((!videoComplete) && (percentageCompleted > 99)) {
//         console.log('VIDEO_100');
//         videoComplete = true;
//
//         window.dataLayer = window.dataLayer || [];
//         window.dataLayer.push({
//             'event': 'playComplete'
//         });
//     }
//
//
// }